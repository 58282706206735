body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* body root div (public/index.html) */
body>div#root {
  padding-top: 10px; /* padding at window top */
}

p a {
  color: rgb(48, 11, 11);
  text-decoration: underline;
  text-decoration-color: rgb(123, 179, 44);
}

p a:hover {
  color: rgb(123, 179, 44);
}

h2 {
  text-align: center;
}

h3 {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
}

blockquote {
  border: 1px solid rgb(48, 11, 11);
  padding: 50px 0 0;
  /*quotes: "\201C""\201D""\2018""\2019";*/
}

blockquote p {
  padding: 0 50px;
}

blockquote:before {
  color: rgb(123, 179, 44);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  display: block;
  clear: both;
  float: left;
  margin-left: 10px;
}

blockquote:after {
  color: rgb(123, 179, 44);
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  display: block;
  clear: both;
  float: right;
  margin-right: 10px;
}

.container-fluid {
  padding: 0;
}

/* nav links */
.navbar-nav .nav-item {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px; /* margin between links */
}

#login.container,
#password.container,
#signup.container,
#bookings.container,
#profile.container {
  padding-top: 50px;
  min-height: 500px;
}

.navbar-nav .btn,
#security.container-fluid .btn,
.card-footer .btn,
.book-now.btn {
  cursor: pointer;
  font-weight: bolder;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(123, 179, 44);
}

#book-form .btn-primary {
  cursor: pointer;
  font-weight: bolder;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: #ff3399;
}

.navbar-nav a.btn:hover {
  color: rgb(255, 255, 255);
}

.navbar {
  margin: 20px 0 0 0;
  background-color: rgb(235, 231, 231);
}

#header.container-fluid {
  background-color: #4c4a5a;
  background-image: url(/img/header.png);
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#header.container-fluid h2 {
  color: rgb(255, 255, 255);
  font-weight: 900;
  font-size: 3em;
  text-transform: uppercase;
}

#book-form.container {
  margin-top: 50px;
  margin-bottom: 50px;
}

#book-form.container {
  padding: 20px;
}

#environment.container {
  padding-top: 50px;
  padding-bottom: 50px;
}

#environment.container p {
  text-align: center;
}

#book-form.container,
#about.container-fluid,
#adaptive.container-fluid {
  background-color: rgb(235, 231, 231);
}

#about.container-fluid,
#facility.container-fluid {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

#about.container-fluid img,
#facility.container-fluid img {
  height: 75px;
  margin-bottom: 10px;
}

#about.container-fluid,
#adaptive.container-fluid {
  background-color: rgb(235, 231, 231);
}

#footer.container-fluid a {
  color: rgb(255, 255, 255);
}

.navbar-nav a:hover,
#footer.container-fluid a:hover {
  color: rgb(123, 179, 44);
}

#facility.container-fluid {
  color: rgb(255, 255, 255);
  background-color: rgb(123, 179, 44);
}

#about.container-fluid,
#facility.container-fluid {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

#about.container-fluid img,
#facility.container-fluid img {
  height: 75px;
  margin-bottom: 10px;
}

#facility.container-fluid .btn {
  background-color: rgb(48, 11, 11);
}

#security.container-fluid {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgb(48, 11, 11);
  color: rgb(255, 255, 255);
  text-align: center;
}

#footer.container-fluid {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(48, 11, 11);
  color: rgb(255, 255, 255);
  text-align: center;
}

#footer.container-fluid hr {
  border-top: 1px solid rgb(255, 255, 255);
}

#lockers.container-fluid {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

#lair.container,
#bike.container-fluid {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

#book-units .card-header {
  font-size: 1em;
}

#book-units .card-header {
  font-size: 1em;
  margin-bottom: 20px;
}

#bike .card-header,
#lair .card-header,
#book-units .card-header {
  background-color: rgb(48, 11, 11);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
}

#bookings .card-header {
  cursor: pointer;
  background-color: rgb(235, 231, 231);
  color: rgb(48, 11, 11);
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
}

.card-footer {
  background-color: rgb(48, 11, 11);
}

.btn {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border: none;
}

#adaptive.container-fluid {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

/*
hot pink, bold text for:
- active tab title
- active card header
- active card title
- active price
- checked radio button label
*/
tr.active,
span.active,
a.nav-item.nav-link.active,
.card.active .card-header h5,
.card.active .card-title,
li.price.active span,
.form-check-label.active,
small.active {
  color: #ff3399;
  font-weight: bolder;
}

span.success {
  color: rgb(123, 179, 44);
  font-weight: bolder;
}

.card.active {
  border-width: 2px;
  border-color: #ff3399;
}

.tab-pane {
  padding-top: 30px;
}

.card-header span.availability-zero strong {
  color: #ff4000;
}

.card-header span.availability-low strong {
  color: #ff6a00;
}

.card-header span.availability-high strong {
  color: #008000;
}

li.price.inactive {
  text-decoration: line-through;
}


/* stripe */
#stripe-payment-method {
  padding: 0.6em;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ced4da;
  border-radius: .25rem;
}